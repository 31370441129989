import React from 'react';
import "./aboutworkStyle.scss";
import sampleimg from "../images/worksample.jpg";

export default function worksample(){
    return(
        <div>
            <section class="section is-medium level">
                <div className="column is-6 has-text-right">
                    <img className="sampleimg" src={sampleimg}/>
                </div>
                <div className="column is-6">
                    <h3 className="sampleem"><strong>Happy Children</strong>, <em>my first painting since I was published for the first time</em></h3>
                    <p className="emsubheading">And that's how it started. Back to my childhood. Starting the role I've been given myself...</p>
                </div>
            </section>
            <div className="briefownerinfo">
                <h4 className="ownerbackground"><strong>Born into an upper-middle-class family</strong></h4>
            <p>
                Born into an upper-middle-class family, Van Gogh drew as a child and was serious, quiet, and thoughtful. As a young man he worked as an art dealer, often travelling, 
                but became depressed after he was transferred to London. He turned to religion and spent time as a Protestant missionary in southern Belgium. He drifted in ill health and solitude before taking up painting in 1881, having moved back home with his parents. <br/>
                <br/>His younger brother Theo supported him financially, and the two kept a long correspondence by letter. His early works, mostly still lifes and depictions of peasant labourers, contain few signs of the vivid colour that distinguished his later work. <br/><br/>
                In 1886, he moved to Paris, where he met members of the avant-garde, including Émile Bernard and Paul Gauguin, who were reacting against the Impressionist sensibility. As his work developed he created a new approach to still lifes and local landscapes. 
                His paintings grew brighter in colour as he developed a style that became fully realised during his stay in Arles in the south of France in 1888. During this period he broadened his subject matter to include series of olive trees, wheat fields and sunflowers. 
            </p>
        </div>
        <section class="section is-medium level">
            <div className="column is-6">
                <h3 className="sampleem2"><strong>Happy Children</strong>, <em>my first painting since I was<br/> published for the first time</em></h3>
                <p className="emsubheading2">And that's how it started. Back to my childhood. Starting the role<br/> I've been given myself...</p>
            </div>
            <div className="column is-6">
                <img className="sampleimg" src={sampleimg}/>
            </div>
        </section>
        </div>
    )
}