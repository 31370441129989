import React from 'react';
import "./aboutHeroStyle.scss";
import ownerimg from "../images/actorImg/download.jpg";

export default function aboutHeroSection(){
    return(
    <div>
        <section class="section is-medium level herosection">
            <div className="column is-6">
                <h1 className="ownername">Owner's Name</h1>
                <h3 className="ownersubheading">I consider myself a nostalgic artist lost in future. My journey is guided by the miracle of art. </h3>
            </div>
            <div className="column is-6 imgdiv">
               <img className="ownerimg" src={ownerimg} />
            </div>
        </section>
        <div className="quotediv column is-8">
            <em className="heroem">I've always loved to draw. It's something inside me, with no control, just a<br/> surrender to my pencil. I think it was God all the time...</em>
        </div>
        <div className="briefownerinfo">
            <p>
                <strong>Vincent Willem van Gogh</strong>
                , 30 March 1853 – 29 July 1890, was a Dutch post-impressionist painter who is among the most famous and influential figures in the history of Western art. 
                In just over a decade, he created about 2,100 artworks, including around 860 oil paintings, most of which date from the last two years of his life. 
                They include landscapes, still lifes, portraits and self-portraits, and are characterised by bold colours and dramatic, impulsive and expressive brushwork that contributed to the foundations of modern art. 
                He was not commercially successful, and his suicide at 37 came after years of mental illness, depression and poverty.<br/>
                <br/>Van Gogh suffered from psychotic episodes and delusions and though he worried about his mental stability, he often neglected his physical health, did not eat properly and drank heavily. 
                His friendship with Gauguin ended after a confrontation with a razor when, in a rage, he severed part of his own left ear. He spent time in psychiatric hospitals, including a period at Saint-Rémy. 
                After he discharged himself and moved to the Auberge Ravoux in Auvers-sur-Oise near Paris, he came under the care of the homeopathic doctor Paul Gachet. His depression continued and on 27 July 1890, Van Gogh shot himself in the chest with a Lefaucheux revolver.[6] He died from his injuries two days later.<br/>
            </p>
        </div>
    </div>
    )
}