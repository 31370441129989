import React from 'react';
import Navbar from "../components/navbar";
import Hero from "../components/aboutherosection";
import Woksample from "../components/aboutworksample";
import Footer from "../components/footer";
 
export default function aboutpage(){
    return(
        <div>
           <Navbar />
           <Hero />
           <Woksample />
           <Footer />
        </div>
    )
}